<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'LearnMap',
}
</script>

<style scoped lang="scss">

</style>
