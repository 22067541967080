import request from '@/utils/RequestUnlimited';

// 活动-获取列表
export function getshow (params) {
  return request({
    url: '/hdla/v1/yearVideo/admin/show',
    method: 'get',
    params,
  });
}


// 活动-新增修改
export function edit (params) {
  return request({
    url: '/hdla/v1/yearVideo/admin/edit',
    method: 'post',
    data: params,
  });
}

// 活动-删除
export function remove(params) {
  return request({
    url: `/hdla/v1/yearVideo/admin/remove/${params.id}`,
    method: 'delete',
    data: params,
  });
}


export function upload(params) {
  return request({
    url: `/ask/app/upload`,
    method: 'post',
    data: params,
  });
}



