<template>
  <div class="app-page-fixed-column ac-sequence">
    <CommonTitle :titles="titles"></CommonTitle>

    <div class="row row-column-start app-pfc-main">
      <a-table ref="tableRef" :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button type="link" size="small" @click="pinned(record)">{{ record.isTop ? '取消置顶' : '置顶' }}</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination
            v-if="pagination && pagination.total"
            :pagination="pagination"
            class="pagination"
            @change="onPageChange"
            @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <div class="row row-center">
      <div class="col col-center">
        <a-button @click="cancel">返回</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getOnActivityList, setTop} from '@/api/activityCenter/Index';
import Common from '@/utils/Common';

import CommonTitle from '@components/CommonTitle';
import Pagination, {getPagination} from '@components/Pagination';

const columns = [
  {
    width: '40px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '180px',
    align: 'center',
    title: '活动名称',
    dataIndex: 'activity_name',
    key: 'activity_name',
  },
  {
    width: '80px',
    align: 'center',
    title: '活动状态',
    key: 'activity_state',
    dataIndex: 'activity_state',
  },
  {
    width: '140px',
    align: 'center',
    title: '上架时间',
    key: 'upload_at',
    dataIndex: 'upload_at',
  },
  {
    width: '120px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  name: 'Sequence',
  components: {CommonTitle, Pagination},
  data() {
    return {
      loading: false,
      columns,
      data: [],
      titles: [{title: '活动管理'}, {title: '活动入口排序'}],

      pagination: getPagination(),
    }
  },
  created() {
    this.getData(1, this.pagination.defaultPageSize);
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    pinned(item) {
      this.$confirm({
        content: `确认${item.isTop ? '取消' : ''}置顶活动: ${item.activity_name}？`,
        onOk: () => this.confirmPinned(item),
      });
    },
    async confirmPinned(item) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await setTop({id: item.id}).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error({content: data?.message || `${item.isTop ? '取消' : ''}置顶失败`});
      }
      this.$message.success({content: data?.message || `${item.isTop ? '取消' : ''}置顶成功`});
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        page: page,
        per_page: pageSize,
      };

      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getOnActivityList(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error({content: data?.message || '获取失败'});
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
  },
}
</script>

<style scoped lang="scss">
.ac-sequence {
  width: 100%;
}
</style>
