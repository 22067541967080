<template>
  <div>
    <a-modal
      class="hny-model"
      title="红旗845正月大拜年"
      :visible="showModelValue"
      :confirm-loading="loading"
      okText="保存"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskClosable="false"
      width="800px"
    >
      <a-form-model
        :model="form"
        :form="form"
        :rules="rules"
        ref="formModelRef"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-form-model-item ref="title" label="标题/作者" prop="title">
          <a-input v-model="form.title" placeholder="请输入作者名称"></a-input>
        </a-form-model-item>
        <a-form-model-item
          ref="agency_post"
          label="作者岗位机构信息"
          prop="agency_post"
        >
          <a-input
            v-model="form.agency_post"
            placeholder="请输入作者岗位机构信息"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item ref="day" label="展示日期" prop="day">
          <a-date-picker @change="onChange" v-model="form.day" />
        </a-form-model-item>
        <a-form-model-item
          ref="share_msg"
          label="视频分享的描述"
          prop="share_msg"
        >
          <a-input
            v-model="form.share_msg"
            placeholder="请输入视频分享的描述"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item ref="desc" label="视频的介绍" prop="desc">
          <Editor :editor-html="form.desc" @editValue="onEditChange"></Editor>
        </a-form-model-item>
        <a-form-model-item
          ref="first_video"
          label="拜年视频"
          prop="first_video"
        >
          <Upload :file-list="firstList" @upload="onUploadFirst"></Upload>
        </a-form-model-item>
        <a-form-model-item
          ref="second_video"
          label="活动视频"
          prop="second_video"
        >
          <Upload :file-list="secondList" @upload="onUploadSecond"></Upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import Editor from './Editor.vue';
import Upload from './Upload.vue';
import { edit } from '../api/Index';

export default {
  name: 'hny-create-video',
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    data: {
      type: Object,
      default: () => undefined
    }
  },
  watch: {
    visible: {
      handler(value, oldvalue) {
        console.log(value, oldvalue);
        this.showModelValue = value;
      }
    },
    data: {
      handler(value) {
        this.init(value);
      }
    }
  },
  data() {
    return {
      form: {
        title: '', // 话题标题
        agency_post: '',
        day: '',
        desc: '',
        first_video: '',
        second_video: '',
        share_msg: ''
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入作者名称'
          }
        ],
        agency_post: [
          {
            required: true,
            message: '请输入岗位机构信息!'
          }
        ],
        day: [
          {
            required: true,
            message: '请选择展示日期!'
          }
        ],
        desc: [
          {
            required: true,
            message: '请输入视频的介绍!'
          }
        ],
        first_video: [
          {
            required: true,
            message: '请上传拜年视频!'
          }
        ],
        second_video: [
          {
            required: true,
            message: '请上传分享视频!'
          }
        ],
        share_msg: [
          {
            required: true,
            message: '请输入视频分享的描述!'
          }
        ]
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      loading: false,
      showModelValue: false,
      firstList: [],
      secondList: []
    };
  },
  components: { Editor, Upload },
  methods: {
    async save() {
      const data = await edit(this.form);
      if (!(data && data.error_code === 0)) {
        this.$message.error(data.message || '保存失败');
        return;
      }
      this.$emit('onChange', true);
      this.$message.success(data.message || '保存成功');
    },
    handleOk() {
      this.validate();
    },
    handleCancel() {
      this.$emit('onChange', false);
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formModelRef.validate((validated) => {
          if (validated) {
            this.save();
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    onChange(date, dateStr) {
      console.log(this.day);
      this.form.day = dateStr;
    },
    onUploadFirst(data) {
      this.form.first_video = data?.url || undefined;
      this.form.first_shot = data?.screentshot || undefined;
    },
    onUploadSecond(data) {
      this.form.second_video = data.url;
      this.form.second_shot = data.screentshot;
    },
    onEditChange(value) {
      this.form.desc = value;
    },
    init(value) {
      this.showModelValue = this.visible;
      if (this.data) {
        this.form = {
          ...value
        };
        this.firstList = value?.first_video ? [value?.first_video] : [];
        this.secondList = value?.second_video ? [value?.second_video] : [];
      } else {
        this.form = {
          title: '', // 话题标题
          agency_post: '',
          day: '',
          desc: '',
          first_video: '',
          second_video: ''
        };
        this.firstList = [];
        this.secondList = [];
      }
      console.log(
        'onMounted',
        this.form,
        value,
        this.firstList,
        this.secondList,
        value?.first_video,
        value?.second_video
      );
    }
  },
  mounted() {
    this.init(this.data);
  }
};
</script>
<style scoped lang="scss">
.hny-model {
  width: 800px;
}
</style>