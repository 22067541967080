<template>
  <a-upload
    :directory="false"
    :customRequest="onCustomRequest"
    :multiple="false"
    :accept="accept"
    :default-file-list="defaultFileList"
    :file-list="defaultFileList"
    :disabled="loading"
    :remove="onRemove"
  >
    <a-button :disabled="loading"><a-icon type="upload" /> 上传文件</a-button>
  </a-upload>
</template>

<script>
import { upload } from '../api/Index';
export default {
  props: {
    fileList: {
      default: () => []
    }
  },
  watch: {
    fileList: {
      handler(value) {
        console.log(value);
        this.Init(value);
      }
    }
  },
  data() {
    return {
      accept: 'video/mp4',
      loading: false,
      defaultFileList: undefined
    };
  },
  mounted() {
    console.log(this.fileList);
    this.Init(this.fileList);
  },
  methods: {
    async upload({ file }) {
      this.loading = true;
      const params = new FormData();
      params.append('video', file);
      const data = await upload(params).finally(() => {
        this.loading = false;
        this.defaultFileList[0].status = 'done';
      });
      console.log('upload', data);
      if (!(data || data.erro_code === 0)) {
        this.defaultFileList[0].status = 'error';
        this.$message.error(data?.message || '上传失败,请稍后重试');
        return false;
      }
      this.$message.success(data?.message || '上传成功');
      this.$emit('upload', data.data);
      return true;
    },
    onCustomRequest(file) {
      if (!(file.file.type === 'video/mp4')) {
        this.$message.error('上传文件失败,文件格式不正确');
        return false;
      }
      if (this.loading === true) {
        return;
      }

      const fileList = [];
      fileList.push(file.file);
      this.defaultFileList = fileList;
      this.defaultFileList[0].status = 'uploading';
      this.upload(file);
    },
    onRemove() {
      this.defaultFileList = [];
      this.$emit('upload', '');
    },
    Init(value) {
      if (value.length > 0) {
        const namesArr = value[0].split('/');
        this.defaultFileList = [
          {
            uid: 'uid',
            name: namesArr[namesArr.length-1],
            status: 'done',
            url: value[0]
          }
        ];
      } else {
        this.defaultFileList = [];
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>