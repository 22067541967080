<template>
  <div class="happy-new-year">
    <CommonTitle :titles="titles">
      <div slot="buttons" class="btn-group">
        <a-button type="danger" @click="create">新增拜年视频</a-button>
      </div>
    </CommonTitle>
    <div class="hny-table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :bordered="bordered"
        :row-key="rowKey"
        class="table small-cell-table"
      >
        <template slot="first_video" slot-scope="text">
          <a target="_blank" :href="text" :title="text"> 点击查看 </a>
        </template>
        <template slot="second_video" slot-scope="text">
          <a target="_blank" :href="text" :title="text">点击查看 </a>
        </template>
        <template slot="desc" slot-scope="text">
          <a @click="onShowHtml(text)" title="点击查看详细">
            <span class="desc-title" v-html="text"></span>
          </a>
        </template>
        <template slot="share_msg" slot-scope="text">
          <a @click="onShowHtml(text)" title="点击查看详细">
            <span class="desc-title">{{ text }}</span>
          </a>
        </template>
        <template slot="action" slot-scope="text, recode">
          <a @click="onEdit(recode)">编辑 </a>
          <a @click="onDelete(recode)">删除 </a>
        </template>
      </a-table>
    </div>
    <CreateVideo
      @onChange="onChange"
      :visible="visible"
      v-if="visible"
      :data="dataModel"
    ></CreateVideo>

    <a-modal
      v-if="showHtml"
      v-model="showHtmlVisible"
      title="预览"
      @ok="closeModel"
      @cancel="closeModel"
    >
      <div v-html="showHtml"></div>
    </a-modal>
  </div>
</template>

<script>
import CommonTitle from '@components/CommonTitle.vue';
import { getshow, remove } from './api/Index';
import CreateVideo from './components/CreateVideo.vue';
const columns = [
  {
    dataIndex: 'id',
    key: 'id',
    title: '编号',
    align: 'center'
  },
  {
    dataIndex: 'title',
    key: 'title',
    title: '作者',
    align: 'center'
  },
  {
    dataIndex: 'agency_post',
    key: 'agency_post',
    title: '作者所在机构',
    align: 'center'
  },
  {
    dataIndex: 'day',
    key: 'day',
    title: '展示日期',
    align: 'center'
  },
  {
    dataIndex: 'first_video',
    key: 'first_video',
    title: '拜年视频',
    align: 'center',
    scopedSlots: { customRender: 'first_video' }
  },
  {
    dataIndex: 'second_video',
    key: 'second_video',
    title: '分享视频',
    align: 'center',
    scopedSlots: { customRender: 'second_video' }
  },
  {
    dataIndex: 'share_msg',
    key: 'share_msg',
    title: '视频分享的描述',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'share_msg' }
  },
  {
    dataIndex: 'desc',
    key: 'desc',
    title: '作品描述',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'desc' }
  },
  {
    dataIndex: 'first_brower',
    key: 'first_brower',
    title: '拜年视频浏览量',
    align: 'center'
  },
  {
    dataIndex: 'second_brower',
    key: 'second_brower',
    title: '分享视频浏览量',
    align: 'center'
  },
  {
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
];
const dataSource = [];

export default {
  data() {
    return {
      titles: [{ title: '活动管理' }, { title: '红旗845正月大拜年' }],
      columns,
      rowKey: 'id',
      bordered: true,
      loading: false,
      visible: false,
      showHtmlVisible: false,
      dataSource,
      dataModel: undefined,
      showHtml: undefined
    };
  },
  components: {
    CommonTitle,
    CreateVideo
  },
  methods: {
    async getshow() {
      this.loading = true;
      const data = await getshow().finally(() => {
        this.loading = false;
      });
      if (!(data || data.error_code === 0)) {
        return false;
      }
      this.dataSource = data.data;
    },
    async remove(id) {
      this.loading = true;
      const data = await remove({
        id
      }).finally(() => (this.loading = false));
      if (!(data || data.error_code === 0)) {
        this.$message.error(data.message || '删除失败');
        return;
      }
      this.getshow();
      this.$message.success(data.message || '删除成功');
    },
    onSizeChange() {},
    onPageChange() {},
    create() {
      this.visible = true;
      this.dataModel = undefined;
    },
    onEdit(item) {
      this.visible = true;
      this.dataModel = item;
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确认删除当前拜年视频',
        onOk: () => {
          this.remove(item.id);
        }
      });
      console.log(item);
    },
    onChange(value) {
      if (!value) {
        this.visible = false;
        return;
      }
      this.visible = false;
      this.getshow();
    },
    onShowHtml(value) {
      this.showHtmlVisible = true;
      this.showHtml = value;
    },
    closeModel() {
      this.showHtmlVisible = false;
      this.showHtml = '';
    }
  },
  mounted() {
    this.getshow();
  }
};
</script>

<style scoped lang="scss">
.happy-new-year {
  width: 100%;
  height: 100%;
}
.hny-table {
  padding: 20px;
}
.desc-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  width: 200px;
  height: 40px;
  line-height: 40px;
  display: block;
  cursor: pointer;
  text-align: center;
}
</style>
<style >
.ql-align-center {
  text-align: center;
}
li::marker {
  font-size: 16px;
}
.ql-align-justify {
  font-size: 26px;
}
</style>