<template>
  <div class="ac-edit-link-modal">
    <a-modal
      :visible="visible"
      :title="title"
      :confirm-loading="confirmLoading"
      :mask-closable="false"
      :centered="true"
      @ok="confirm"
      @cancel="close">
      <a-form>
        <a-form-item label="链接名称">
          <a-input ref="inputRef" v-model="name" placeholder="请输入链接名称"></a-input>
          <span class="tip-text">6字符以内</span>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'EditLinkModal',
  props: {
    data: {type: Object, default: () => new Object()},
  },
  computed: {
    title() {
      return this.data?.name ? '修改链接名称' : '新增链接名称';
    },
  },
  data() {
    return {
      visible: true,
      confirmLoading: false,

      name: '',
    }
  },
  created() {
    this.name = this.data?.name || '';
  },
  mounted() {
    this.$nextTick(() => this.$refs.inputRef?.focus());
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      if (!this.name) {
        return this.$message.info({content: '请输入链接名称'});
      }
      if (this.name.length > 6) {
        return this.$message.info({content: '链接名称应在 6 字符以内'});
      }

      const data = Object.assign({}, this.data, {name: this.name});
      this.$emit('close', data);
    },
  },
}
</script>

<style scoped lang="scss">
.ac-edit-link-modal {
}
</style>
