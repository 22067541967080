<template>
  <div class="ac-detail">
    <CommonTitle :titles="titles"></CommonTitle>

    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit.prevent="onSubmit">

      <a-form-item label="活动名称">
        <a-input v-decorator="['title', { initialValue: title, rules: [{ required: true, message: '请输入活动名称!' }] }]"
                 :disabled="readOnly"
                 placeholder="请输入活动名称"/>
      </a-form-item>

      <a-form-item label="封面图片">
        <div class="row row-left">
          <a-upload
            v-decorator="['coverUrl', { initialValue: coverUrl, rules: [{ required: true, message: '请上传封面图片!' }] }]"
            list-type="picture-card"
            style="width: auto; margin-bottom: -8px;"
            :disabled="readOnly"
            :show-upload-list="false"
            :custom-request="customRequest">
            <img v-if="coverUrl" :src="coverUrl" alt="封面" class="cover-img"/>
            <div v-else>
              <a-icon :type="picLoading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <div class="col col-left">
            <a-button @click="previewImg">查看</a-button>
            <a-button type="danger" @click="deleteCoverImg">删除</a-button>
          </div>
        </div>
      </a-form-item>

      <a-form-item label="活动起止时间">
        <a-range-picker
          v-decorator="['activityTimes', { initialValue: activityTimes, rules: [{ required: true, message: '请选择活动起止时间!' }] }]"
          :show-time="true" :disabled="readOnly"></a-range-picker>
      </a-form-item>

      <a-form-item label="活动跳转类型">
        <a-radio-group
          v-decorator="['jumpType', { initialValue: jumpType, rules: [{ required: true, message: '请选择活动跳转类型!' }] }]"
          @change="jumpTypeChange">
          <a-radio :value="1">h5链接</a-radio>
          <a-radio :value="2">小程序</a-radio>
          <a-radio :value="3">App内跳转</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item v-if="jumpType === 3" label="APP内跳转页面" style="margin-bottom: 5px;">
        <SelectAppJumpPage :default-selected-id="appJumpPage" label="" @select="selectAppJumpPage"></SelectAppJumpPage>
        <span class="tip-text">当活动跳转类型选择“App内跳转”，此项需选择；否则请忽略</span>
      </a-form-item>

      <template v-else>
        <a-radio-group v-model="selectedLinkType" class="radio-group">

          <div class="ant-row">
            <div class="ant-col-5 row row-right no-padding radio-box">
              <a-radio name="link" value="link1">宣传链接:</a-radio>
            </div>

            <div class="ant-col-16">
              <a-input v-model="link1.url" placeholder="请输入链接"></a-input>
            </div>
          </div>

          <div class="ant-row">
            <div class="ant-col-5 row row-right no-padding radio-box">
              <a-radio name="link" value="link2">进行链接:</a-radio>
            </div>

            <div class="ant-col-16">
              <a-input v-model="link2.url" placeholder="请输入链接"></a-input>
            </div>
          </div>

          <div class="ant-row">
            <div class="ant-col-5 row row-right no-padding radio-box">
              <a-radio name="link" value="link3">结束链接:</a-radio>
            </div>

            <div class="ant-col-16">
              <a-input v-model="link3.url" placeholder="请输入链接"></a-input>
            </div>
          </div>

          <div v-if="link4 && link4.name" class="ant-row">
            <div class="ant-col-5 row row-right no-padding radio-box">
              <a-button type="link" icon="edit" class="btn" @click="editLink(link4)"/>
              <a-radio name="link" value="link4">{{ link4.name }}:</a-radio>
            </div>

            <div class="ant-col-16">
              <a-input v-model="link4.url" placeholder="请输入链接"></a-input>
            </div>
          </div>

          <div v-if="link5 && link5.name" class="ant-row">
            <div class="ant-col-5 row row-right no-padding radio-box">
              <a-button type="link" icon="edit" class="btn" @click="editLink(link5)"/>
              <a-radio name="link" value="link5">{{ link5.name }}:</a-radio>
            </div>

            <div class="ant-col-16">
              <a-input v-model="link5.url" placeholder="请输入链接"></a-input>
            </div>
          </div>

          <div v-if="link6 && link6.name" class="ant-row">
            <div class="ant-col-5 row row-right no-padding radio-box">
              <a-button type="link" icon="edit" class="btn" @click="editLink(link6)"/>
              <a-radio name="link" value="link6">{{ link6.name }}:</a-radio>
            </div>

            <div class="ant-col-16">
              <a-input v-model="link6.url" placeholder="请输入链接"></a-input>
            </div>
          </div>

          <div v-if="link7 && link7.name" class="ant-row">
            <div class="ant-col-5 row row-right no-padding radio-box">
              <a-button type="link" icon="edit" class="btn" @click="editLink(link7)"/>
              <a-radio name="link" value="link7">{{ link7.name }}:</a-radio>
            </div>

            <div class="ant-col-16">
              <a-input v-model="link7.url" placeholder="请输入链接"></a-input>
            </div>
          </div>

        </a-radio-group>

        <a-form-item :wrapper-col="{ offset: 5}">
          <div>
            <a-button @click="addNewLink">新增链接</a-button>
            <span class="tip-text">最多新增4个链接</span>
          </div>
        </a-form-item>
      </template>

      <a-form-item label="评论功能">
        <a-radio-group
          v-decorator="['isComment', { initialValue: isComment, rules: [{ required: true, message: '请选择是否开启评论功能!' }] }]">
          <a-radio :value="1">开启</a-radio>
          <a-radio :value="0">关闭</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="上架时间">
        <a-date-picker
          v-decorator="['pullOnTime', { initialValue: pullOnTime, rules: [{ required: true, message: '请选择上架时间!' }] }]"
          :show-time="true"></a-date-picker>
        <div class="block-tip tip-text">活动上架时间，设定后将在[活动中心]内显示入口</div>
      </a-form-item>


      <a-form-item label="推送岗位" style="margin-bottom: 5px;">
        <SelectJob :show-label="false" :default-selected-ids="jobIds" @select="selectJob"></SelectJob>
        <span class="tip-text">不选择则推送全部岗位</span>
      </a-form-item>

      <a-form-item label="推送组织机构" style="margin-bottom: 5px;">
        <SelectOrg :show-label="false" :disabled="readOnly" :default-selected-ids="orgIds"
                   @select="selectOrg"></SelectOrg>
        <span class="tip-text">不选择则推送全部组织机构</span>
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 5}">
        <a-button :disabled="readOnly" type="primary" html-type="submit">保存</a-button>
        <a-button @click="cancel">取消</a-button>
      </a-form-item>

    </a-form>

    <ImageViewer ref="imageViewerRef" :url="coverUrl"></ImageViewer>
    <EditLinkModal v-if="editLinkModalShown" :data="selectedLinkForEdit" @close="closeEditLinkModal"></EditLinkModal>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import * as moment from 'moment';

import {getOSSInfo, getImageUrl} from '@/api/Common';
import {saveActivity} from '@/api/activityCenter/Index';

import OssUploader, {getRandomString} from '@/utils/OssUploader';

import SelectAppJumpPage from '@components/SelectAppJumpPage';
import CommonTitle from '@components/CommonTitle';
import EditLinkModal from './EditLinkModal';
import ImageViewer from '@components/ImageViewer';
import SelectJob from '@components/SelectJob';
import SelectOrg from '@components/SelectOrg';

export default {
  name: 'Detail',
  components: {SelectAppJumpPage, CommonTitle, EditLinkModal, ImageViewer, SelectJob, SelectOrg},
  computed: {
    ...mapState({
      detail: state => state.ActivityCenter.detail,
    }),
  },
  watch: {
    'detail.info.activity_name'(name) {
      this.title = name || '';
    },
    'detail.info.activity_img'(img) {
      this.coverUrl = img || '';
    },
    'detail.info.activity_start'(startTime) {
      const times = [...this.activityTimes];
      times[0] = moment(startTime, 'YYYY-MM-DD HH:mm');
      this.activityTimes = times;
    },
    'detail.info.activity_end'(endTime) {
      const times = [...this.activityTimes];
      times[1] = moment(endTime, 'YYYY-MM-DD HH:mm');
      this.activityTimes = times;
    },
    'detail.info.turn_type'(type) {
      this.jumpType = type || '';
    },
    'detail.info.app_jump_type_id'(id) {
      this.appJumpPage = id;
    },
    'detail.info.link_json.checkLink'(checkLink) {
      if (!checkLink) {
        return;
      }
      this.selectedLinkType = 'link' + checkLink.split('.')[1] || undefined;
    },

    'detail.info.link_json.otherLink'(links) {
      if (!links || !links.length) {
        return;
      }
      links.forEach((link, idx) => {
        if (idx >= 3) {
          if (link.link_name) {
            this['link' + (idx + 1)].name = link.link_name;
          }
        }
        if (link.link_value) {
          this['link' + (idx + 1)].url = link.link_value;
        }
      });
    },

    'detail.info.is_open'(isComment) {
      this.isComment = isComment;
    },
    'detail.info.upload_at'(time) {
      this.pullOnTime = moment(time, 'YYYY-MM-DD HH:mm');
    },

    'detail.orgIds'(ids) {
      if (!ids) {
        this.orgIds = '';
      } else {
        this.orgIds = +this.detail?.info?.org_opertion === 2 ? (Array.from(new Set(ids.split(','))).join(',') || '') : '';
      }
    },
    'detail.postIds'(ids) {
      if (!ids) {
        this.jobIds = '';
      } else {
        this.jobIds = +this.detail?.info?.post_opertion === 2 ? (Array.from(new Set(ids.split(','))).join(',') || '') : '';
      }
    },
  },
  data() {
    return {
      id: 0,
      readOnly: false,

      labelSize: 140,
      titles: [{title: '活动管理'}, {title: '编辑活动'}],
      loading: false,
      picLoading: false,

      form: this.$form.createForm(this, {name: 'coordinated'}),

      imageViewerShown: false,
      editLinkModalShown: false,

      title: '',
      coverUrl: '',
      activityTimes: [],
      jumpType: '',
      appJumpPage: undefined,

      selectedLinkType: '',
      link1: {},
      link2: {},
      link3: {},
      link4: {},
      link5: {},
      link6: {},
      link7: {},

      isComment: '',
      pullOnTime: undefined,
      orgIds: '',
      jobIds: '',

      selectedLinkForEdit: {},
    };
  },
  created() {
    this.$store.dispatch('Common/getAppJumpPage');

    this.id = this.$route?.query?.id || 0;
    this.$store.dispatch('ActivityCenter/getActivity', this.id);
  },
  destroyed() {
    this.$store.commit('ActivityCenter/updateReadOnly', false);
    this.$store.commit('ActivityCenter/updateActivityId', 0);
    this.$store.commit('ActivityCenter/updateDetail', {});
  },
  methods: {
    onSubmit() {
      if (this.readOnly || this.loading) {
        return;
      }
      this.loading = true;

      this.form.validateFields(async (err, values) => {
        if (err) {
          this.$message.info({content: '请填写必填项'});
          return this.loading = false;
        }
        const params = {
          activity_name: values?.title || '',
          activity_img: this.coverUrl || '',
          activity_start: values?.activityTimes[0].format('YYYY-MM-DD HH:mm:ss'),
          activity_end: values?.activityTimes[1].format('YYYY-MM-DD HH:mm:ss'),
          turn_type: values.jumpType,
          is_open: values.isComment,
          upload_at: values.pullOnTime.format('YYYY-MM-DD HH:mm:ss'),
          org_ids: this.orgIds,
          post_ids: this.jobIds,
        };

        this.id && (params.id = this.id);

        if (values.jumpType === 3) {
          params.app_jump_type_id = this.appJumpPage || '';
        } else {
          this.selectedLinkType && (params.link_value = this[this.selectedLinkType]?.url);
          this.selectedLinkType && (params.checkLink = 'appLink.' + this.selectedLinkType.substr(this.selectedLinkType.length - 1, 1));

          this.link1?.url && (params.link_value1 = this.link1?.url);
          this.link2?.url && (params.link_value2 = this.link2?.url);
          this.link3?.url && (params.link_value3 = this.link3?.url);
          this.link4?.url && (params.link_value4 = this.link4?.url);
          this.link5?.url && (params.link_value5 = this.link5?.url);
          this.link6?.url && (params.link_value6 = this.link6?.url);
          this.link7?.url && (params.link_value7 = this.link7?.url);

          this.link4?.name && (params.link_name4 = this.link4?.name);
          this.link5?.name && (params.link_name5 = this.link5?.name);
          this.link6?.name && (params.link_name6 = this.link6?.name);
          this.link7?.name && (params.link_name7 = this.link7?.name);
        }

        await this.save(params).then(() => {
          this.cancel();
        }).finally(() => {
          this.loading = false;
        });
      });
    },

    async save(params) {
      const data = await saveActivity(params).finally();
      if (!data || data.error_code) {
        this.$message.error({content: data?.message || '保存失败'});
        return Promise.reject(data);
      }
      this.$message.success({content: data?.message || '保存成功'});
      return Promise.resolve(data);
    },
    cancel() {
      this.$router.back();
    },

    jumpTypeChange(event) {
      this.jumpType = event.target?.value || undefined;
    },

    previewImg() {
      if (!this.coverUrl) {
        return this.$message.info({content: '请先上传图片'});
      }
      this.$refs.imageViewerRef?.show && this.$refs.imageViewerRef.show();
    },
    deleteCoverImg() {
      if (!this.coverUrl) {
        return;
      }
      this.$confirm({
        content: '确认删除该封面图片？',
        onOk: () => this.confirmDeleteCoverImg(),
      });
    },
    confirmDeleteCoverImg() {
      this.coverUrl = '';
    },

    customRequest({file}) {
      // this.uploadPic(file, this.getFileName(file.name));
      this.uploadPicOvert(file);
    },

    /**
     * 上传音频文件至 OSS
     */
    async uploadPic(data, fileName) {
      this.picLoading = true;
      let host = '';
      let imgUrl = '';
      let key = 'activitycenter';

      return new Promise((resolve, reject) => {
        new OssUploader({
          init: async (up) => {
            const oss = await this.getOssInfo(key);
            host = oss.host;
            imgUrl = oss.img_url;
            key = oss.dir + fileName;

            const now = Date.parse(new Date()) / 1000;
            const expire = parseInt(oss.expire);
            if (expire < now + 3) {
              console.log('oss 过期了', new Date(expire * 1000));
            }
            console.log('oss 没过期');

            const multipartParams = {
              'key': key,
              'policy': oss.policy,
              'OSSAccessKeyId': oss.accessid,
              'success_action_status': '200', // 让服务端返回200,不然，默认会返回204
              'signature': oss.signature,
            };

            up.setOption({
              'url': host,
              'multipart_params': multipartParams,
            });

            up.addFile(data, fileName);

            up.start();
          },
          finish: (up, file, info) => {
            this.picLoading = false;
            if (info.status === 200) {
              console.log('[FileUploaded] success', file, info);
              this.coverUrl = imgUrl + '/' + key;
            } else if (info.status === 203) {
              console.log('[FileUploaded] 上传到OSS成功，但是oss访问用户设置的上传回调服务器失败，失败原因是:' + info.response);
            } else {
              console.log(info.response);
            }
            resolve();
          },
          error: (err) => {
            this.picLoading = false;
            reject(err);
          },
        });
      });
    },

    /**
     * 图片上传至后台
     */
    async uploadPicOvert(file) {
      const formData = new FormData();
      formData.append('file', file);

      const data = await getImageUrl(formData);
      this.coverUrl = data?.data?.url || '';
    },

    /**
     * 获取 OSS 信息
     */
    async getOssInfo(key) {
      const data = await getOSSInfo({dir: key}).catch(() => this.picLoading = false);
      if (!data) {
        this.picLoading = false;
        this.$message.error({message: '上传图片失败'});
        return Promise.reject(data);
      }

      return Promise.resolve(data);
    },

    getFileName(name) {
      const idx = name.lastIndexOf('.');
      const type = idx > -1 ? name.substr(idx) : 'jpg';
      return getRandomString() + type;
    },

    selectAppJumpPage(event) {
      this.appJumpPage = event?.id;
    },

    selectJob(ids) {
      this.jobIds = ids && ids.length ? ids.join(',') : '';
    },
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map(i => i.id).join(',');
    },

    addNewLink() {
      if (this.link7?.name) {
        return this.$message.info({content: '最多只能新增4个链接'});
      }
      this.selectedLinkForEdit = {};
      this.showEditLinkModal();
    },
    editLink(link) {
      this.selectedLinkForEdit = link;
      this.showEditLinkModal();
    },
    showEditLinkModal() {
      this.editLinkModalShown = true;
    },
    closeEditLinkModal(event) {
      this.editLinkModalShown = false;

      if (!event) {
        return;
      }

      if (!this.selectedLinkForEdit?.name) {
        if (!this.link4?.name) {
          this.link4 = {name: event?.name || ''};
        } else if (!this.link5?.name) {
          this.link5 = {name: event?.name || ''};
        } else if (!this.link6?.name) {
          this.link6 = {name: event?.name || ''};
        } else if (!this.link7?.name) {
          this.link7 = {name: event?.name || ''};
        }
      }

      this.selectedLinkForEdit.name = event?.name;
      this.selectedLinkForEdit = {};
    },
  },
};
</script>

<style scoped lang="scss">
.ac-detail {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .cover-img {
    max-width: 122px;
    max-height: 118px;
  }

  .ac-detail-row {
    padding: 8px 18px 8px 0;
  }

  .radio-group {
    width: 100%;

    .ant-row {
      margin-bottom: 12px;
    }
  }

  .radio-box > * {
    margin-right: 0;
    padding-right: 0;
  }

  .radio-box {
    &:hover .btn {
      display: inline-block;
    }

    .btn {
      display: none;
    }
  }
}
</style>
