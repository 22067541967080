<template>
  <BaseLabelAction custom-class="app-select-app-jump-page">
    <span slot="label" v-if="label">{{ label }}</span>

    <a-select v-model="selectedId" :disabled="disabled" :placeholder="placeholder" class="select" @change="select">
      <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
    </a-select>
  </BaseLabelAction>
</template>

<script>
import {mapState} from 'vuex';

import BaseLabelAction from '@components/BaseLabelAction';


export default {
  name: 'SelectAppJumpPage',
  components: {BaseLabelAction},
  props: {
    label: {type: String, default: 'APP内跳转页面'},
    placeholder: {type: String, default: '请选择 APP 内跳转页面'},
    defaultSelectedId: {type: [String, Number], default: ''},
    disabled: {type: Boolean, default: false},
  },
  computed: {
    ...mapState({
      options: state => state.Common.appJumpPage,
    }),
  },
  watch: {
    defaultSelectedId() {
      this.setDefault();
    },
  },
  data() {
    return {
      selectedId: undefined,
    }
  },
  created() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.selectedId = !this.defaultSelectedId ? undefined : +this.defaultSelectedId;
    },

    select() {
      if (!this.options || !this.options.length) {
        return;
      }

      const [item] = this.options.filter(o => o.id === this.selectedId);
      this.$emit('select', item);
    },
  },
}
</script>

<style scoped lang="scss">
.app-select-app-jump-page {
}
</style>
