<template>
  <div>
    <quill-editor
      ref="myLQuillEditor"
      v-model="content"
      :options="editorOption"
      class="editor"
      @change="onEditorChange"
      height="200px"
    >
    </quill-editor>
  </div>
</template>

<script>
// 引入样式和quillEditor
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
const toolbarOptions = [
  // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ['bold'],
  // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ list: 'ordered' }, { list: 'bullet' }],
  // 对齐方式-----[{ align: [] }]
  [{ align: [] }]
];

export default {
  name: 'hny-editor',
  props: {
    editorHtml: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    editorHtml: {
      handler(value) {
        console.log('from.desc', this.editorHtml);
        console.log(value);
        this.content = value;
      }
    }
  },
  components: {
    quillEditor
  },
  data() {
    return {
      content: '',
      editorOption: {
        modules: {
          toolbar: toolbarOptions
        },
        theme: 'snow',
        placeholder: '请输入正文'
        // Some Quill optiosn...
      }
    };
  },
  methods: {
    // 内容改变事件
    onEditorChange(e) {
      console.log('onEditorChange: ', e.html);
      this.$emit('editValue', e.html);
    }
  },
  mounted() {
    console.log('from.desc', this.editorHtml);
    this.content = this.editorHtml;
  }
};
</script>

<style scoped lang="scss">
::v-deep .ql-editor {
  height: 150px;
}
</style>
<style>
/**设置默认字体显示
**/
.ql-container {
  font-size: 26px;
}
.ql-align-center {
  text-align: center;
}
li::before {
  font-size: 26px;
}
</style>