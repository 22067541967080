<template>
  <div class="ac-activity-list">
    <CommonTitle :titles="titles">
      <div slot="buttons" class="btn-group">
        <a-button @click="create">创建活动</a-button>
      </div>
    </CommonTitle>

    <div class="row row-wrap">
      <BaseLabelAction class="mr-30">
        <span slot="label">活动名称</span>
        <a-input v-model="filterTxt" placeholder="输入活动名称" class="input"/>
      </BaseLabelAction>

      <SelectShelveStatus class="mr-30" @select="selectStatus"></SelectShelveStatus>

      <div class="col col-left">
        <a-button type="primary" @click="onSearch">搜索</a-button>
      </div>

      <div class="col col-right">
        <a-button @click="toSequence">活动列表排序</a-button>
      </div>
    </div>

    <div class="row">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button type="link" size="small" @click="editItem(record)">编辑</a-button>
            <a-button v-if="record.activityState === 1" type="link" size="small" @click="pullOff(record)">下架</a-button>
            <a-button v-if="record.activityState === 2" type="link" size="small" @click="pullOn(record)">上架</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {listSubPage} from '@/utils/Navs/ActivityCenterNavs';

import {getActivityList, pullOn, pullOff} from '@/api/activityCenter/Index';
import Common from '@/utils/Common';

import BaseLabelAction from '@components/BaseLabelAction';
import CommonTitle from '@components/CommonTitle';
import Pagination, {getPagination} from '@components/Pagination';
import SelectShelveStatus from '@components/SelectShelveStatus';

const columns = [
  {
    width: '40px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '180px',
    align: 'center',
    title: '活动名称',
    dataIndex: 'activity_name',
    key: 'activity_name',
  },
  {
    width: '80px',
    align: 'center',
    title: '活动状态',
    key: 'activity_state',
    dataIndex: 'activity_state',
  },
  {
    width: '80px',
    align: 'center',
    title: '上架时间',
    key: 'upload_at',
    dataIndex: 'upload_at',
  },
  {
    width: '130px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  name: 'List',
  components: {BaseLabelAction, CommonTitle, Pagination, SelectShelveStatus},
  data() {
    return {
      loading: false,
      columns,
      data: [],
      titles: [{title: '活动管理'}, {title: '活动列表'}],
      filterTxt: '',
      selectedStatus: undefined,

      pagination: getPagination(),
    }
  },
  created() {
    this.getData();
  },
  methods: {
    editItem(item) {
      const path = `${listSubPage[0]?.path}?id=${item.id}`;
      this.$router.push(path);
    },
    create() {
      this.$router.push(listSubPage[0]?.path);
    },
    toSequence() {
      this.$router.push(listSubPage[1]?.path);
    },

    selectStatus(event) {
      this.selectedStatus = event?.id;
    },

    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        title: this.filterTxt,
        page: page,
        per_page: pageSize,
      };
      if (this.selectedStatus !== undefined && this.selectedStatus !== null && this.selectedStatus > -1) {
        params.status = this.selectedStatus;
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getActivityList(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    // 上架
    pullOn(item) {
      this.$confirm({
        content: () => <div>确认上架？</div>,
        onOk: () => {
          this.confirmPullOn(item);
        },
      });
    },
    async confirmPullOn(item) {
      this.loading = true;
      const data = await pullOn(item.id).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error({content: data?.message || '上架失败'});
      }
      this.$message.success({content: data?.message || '上架成功'});
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    // 下架
    pullOff(item) {
      this.$confirm({
        content: () => <div>确认下架？</div>,
        onOk: () => {
          this.confirmPullOff(item);
        },
      });
    },
    async confirmPullOff(item) {
      this.loading = true;
      const data = await pullOff(item.id).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error({content: data?.message || '下架失败'});
      }
      this.$message.success({content: data?.message || '下架成功'});
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
  },
}
</script>

<style scoped lang="scss">
.ac-activity-list {
  width: 100%;

  .input {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }

  .table-cell-wrapper {
    button {
      font-size: 12px;
    }

    button {
      margin-left: 5px;
    }
  }
}
</style>
