<template>
  <div class="ss-select ss-select-job">
    <span v-if="showLabel">岗位：</span>

    <a-select v-model="selectedIds" :allow-clear="true" :mode="mode" :show-search="true"
              :disabled="disabled" placeholder="请选择岗位" class="select"
              :filter-option="filterOption" @change="select">
      <a-select-option v-if="mode === 'multiple'" :value="0" @click="selectAll">全选</a-select-option>
      <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectJob',
  model: {
    prop: 'defaultSelectedIds',
    event: 'change',
  },
  props: {
    showLabel: { type: Boolean, default: true },
    defaultSelectedIds: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    mode: {
      type: String,
      default: 'multiple',
      validator: (val) => ['multiple', 'default', 'tags', 'combobox'].includes(val),
    },
  },
  computed: {
    ...mapState({
      options: state => state.Common.jobList,
    }),
  },
  watch: {
    defaultSelectedIds() {
      this.setDefault();
    },
  },
  data() {
    return {
      selectedIds: [],
      isAllClicked: false,
    };
  },
  created() {
    this.setDefault();
  },
  methods: {
    filterOption(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) > -1;
    },

    selectAll() {
      this.isAllClicked = true;
    },

    setDefault() {
      if (!this.defaultSelectedIds) {
        this.ids = '';
        this.selectedIds = this.mode === 'multiple' ? [] : undefined;
        return;
      }
      const ids = this.defaultSelectedIds.split(',');
      this.selectedIds = this.mode === 'multiple' ? ids.map(i => +i) : ids[0];
    },

    select() {
      if (!this.options || !this.options.length) {
        return;
      }

      // 点击全选按钮全选/取消全选全部选项
      // if (this.isAllClicked) {
      //   if (this.selectedIds && this.selectedIds.includes(0)) {
      //     this.selectedIds = this.options.map(op => op.id);
      //     this.selectedIds.unshift(0);
      //   } else {
      //     this.selectedIds = [];
      //   }
      // }

      const selectedIds = this.mode === 'multiple' ? this.selectedIds : [this.selectedIds];

      if (this.isAllClicked && this.mode === 'multiple') {
        this.selectedIds = this.options.map(op => op.id);
        this.selectedIds.unshift(0);
      }

      this.isAllClicked = false;

      const ids = selectedIds.filter(i => !!i);
      this.$emit('change', ids.join(','));
      this.$emit('select', ids);
      this.$emit('change', ids.join(','));
    },
  },
};
</script>

<style scoped lang="scss">
.ss-select-job {

  .select {
    min-width: 350px;

    ::v-deep .ant-select-selection {
      overflow-y: auto;
      max-height: 120px;
    }
  }
}
</style>
